// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const isProduction = true;
const baseUrl = isProduction ? 'https://jobsmap.site' : 'http://localhost:3000';

export const environment = {
  production: false,
  // Url: 'http://localhost:4000/v1/',
  // socketUrl: 'http://localhost:4000',
  // uploadsUrL: 'http://localhost:4000/img/',
  // uploadsUrL: 'http://localhost:4000/uploads/'

  Url: `${baseUrl}/api/`,
  socketUrl: `${baseUrl}`,
  imageUrL: `${baseUrl}/api/img/`,
  uploadsUrL: `${baseUrl}/api/uploads/`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
