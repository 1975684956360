import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },



  {
    label: 'Data management',
    isTitle: true
  },
  {
    label: 'Job Seekers',
    icon: 'users',
    subItems: [
      {
        label: 'All Job Seekers',
        link: '/general/job_seekers',
      },
      {
        label: 'All Users Who Have Been Hired',
        link: '/general/all-user-hired',
      },
    ]
  },
  {
    label: 'Companies',
    icon: 'globe',
    subItems: [
      {
        label: 'All Companies',
        link: '/general/all-companies',
      },
    ]
  },
  {
    label: 'Jobs',
    icon: 'briefcase',
    subItems: [
      {
        label: 'All Jobs',
        link: '/general/all-jobs',
      },
    ]
  },
  {
    label: 'Categories',
    icon: 'shopping-bag',
    subItems: [
      {
        label: 'Add Category',
        link: '/general/add-category',
      },
      {
        label: 'All Categories',
        link: '/general/categories',
      },
    ]
  },
  {
    label: 'Payment Documents',
    icon: 'credit-card',
    subItems: [
      {
        label: 'All Companies Promotions',
        link: '/general/all-payment_documents',
      },
      {
        label: 'All Companies Payments',
        link: '/general/all-companies-payments',
      },
      {
        label: 'All Users Payments',
        link: '/general/all-users-payments',
      },
    ]
  },
  // {
  //   label: 'Sales',
  //   isTitle: true
  // },
  // {
  //   label: 'Coupons',
  //   icon: 'clipboard',
  //   subItems: [
  //     {
  //       label: 'Add Coupon',
  //       link: '/general/add-coupon',
  //     },
  //     {
  //       label: 'All Coupons',
  //       link: '/general/all-coupons',
  //     },
  //   ]
  // },
  // {
  //   label: 'Subscriptions',
  //   icon: 'anchor',
  //   subItems: [
  //     {
  //       label: 'Add Subscription',
  //       link: '/general/add-subscription',
  //     },
  //     {
  //       label: 'All Subscriptions',
  //       link: '/general/all-subscriptions',
  //     },
  //   ]
  // },
  // {
  //   label: 'Plans',
  //   icon: 'map',
  //   subItems: [
  //     {
  //       label: 'Add Plan',
  //       link: '/general/addPlan',
  //     },
  //     {
  //       label: 'All Plans',
  //       link: '/general/plans',
  //     },

  //   ]
  // },
  {
    label: 'Management',
    isTitle: true
  },
  {
    label: 'Admins',
    icon: 'file-text',
    subItems: [
      {
        label: 'Add Admin',
        link: '/general/add-admin',
      },
      {
        label: 'All Admins',
        link: '/general/all-admins',
      },
    ]
  },


];
