import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; // For rxjs 6
import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})

export class UserRepository {
  constructor(
    private api: ApiService,
  ) { }


  createUser(data: any): Observable<any> {
    return this.api.post('users', data);
  }

  getUsers(page: number = 1): Observable<any> {
    return this.api.get(`users?page=${page}`);
  }

  getAdmins(page: number = 1): Observable<any> {
    return this.api.get(`users?page=${page}&role=admin`);
  }

  getJobSeekers(page: number = 1, search): Observable<any> {
    return search === null ?
      this.api.get(`users?page=${page}&role=user`) :
      this.api.get(`users?page=${page}&role=user&search=${search}`)
  }

  getUser(id): Observable<any> {
    return this.api.get(`users/${id}`);
  }

  updateUser(data: any, id): Observable<any> {
    return this.api.update(`users/${id}`, data);
  }
  deleteUser(id): Observable<any> {
    return this.api.delete(`users/${id}`);
  }


}
