import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthRepository } from 'src/app/repository/api_repository/auth.repository';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthRepository,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('accessToken')) {
      return true;
    } else {
      let refreshToken = {
        "refreshToken": localStorage.getItem('refreshToken')
      }
      this.auth.onLogout(refreshToken).subscribe(logout => {
        localStorage.clear();
      })
    }

    
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;



    // if (!localStorage.getItem('landing')) {
    //   if (localStorage.getItem('accessToken')) {
    //     return true;
    //   } else {
    //     let refreshToken = {
    //       "refreshToken": localStorage.getItem('refreshToken')
    //     }
    //     this.auth.onLogout(refreshToken).subscribe(logout => {
    //       localStorage.clear();
    //     })
    //   }

    //   // not logged in so redirect to login page with the return url
    //   this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    //   return false;
    // } else {
    //   this.router.navigate(['/landing']);
    //   return false;
    // }

  }
}