import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io, Socket } from 'socket.io-client'
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SocketioService {
  socket: Socket
  constructor() {
    this.disconnect();
  }

  connect() {
    this.socket = io(environment.socketUrl);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  isConnect() {
    return this.socket;
  }

  onNewPayment() {
    return fromEvent(this.socket, 'newPaymentDocument');
  }

}
