import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; // For rxjs 6
import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthRepository {
  constructor(
    private api: ApiService,
  ) { }


  onRegister(data: any): Observable<any> {
    return this.api.post('auth/register', data);
  }

  onResetPass(data: any): Observable<any> {
    return this.api.post('auth/reset-password', data);
  }

  onLogIn(data: any): Observable<any> {
    return this.api.post('auth/login', data);
  }

  updateInformation(data: any): Observable<any> {
    return this.api.post('auth/update-info', data);
  }

  forgotPassword(data: any): Observable<any> {
    return this.api.post('auth/forgot-password', data);
  }
  onLogout(data: any): Observable<any> {
    return this.api.post('auth/logout', data)
  }


}
