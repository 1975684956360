import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  _baseUrl: string = environment.Url;
  uploadsUrL = environment.uploadsUrL;

  header = new HttpHeaders();
  headerUpload = new HttpHeaders();
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }
  setHeaders() {
    this.header = this.header.set("extended", "true");
    this.header = this.header.set('Content-Type', 'application/json');
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.header = this.header.set('Authorization', 'Bearer ' + token);
    }
  }
  setHeadersUpload() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.headerUpload = this.headerUpload.set('Authorization', 'Bearer ' + token);
    }
  }
  hideLoader() {
    this.spinner.hide()
  }

  showLoader() {
    this.spinner.show()
  }
  post(url: string, data: any) {
    this.setHeaders();
    return this.http.post(this._baseUrl + url, data, { headers: this.header });
  }

  get(url: string) {
    this.setHeaders();
    return this.http.get(this._baseUrl + url, { headers: this.header });
  }
  update(url: string, data: any) {
    this.setHeaders();
    return this.http.patch(this._baseUrl + url, data, { headers: this.header });
  }

  delete(url: string) {
    this.setHeaders();
    return this.http.delete(this._baseUrl + url,  { headers: this.header });
  }

  //upload images
  uploadImage(container: string, file: File) {
    this.setHeadersUpload();
    let fd = new FormData();
    fd.append('file', file);
    return this.http.post(this._baseUrl + 'images/upload' , fd , { headers: this.headerUpload });
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
