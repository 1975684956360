import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthRepository } from 'src/app/repository/api_repository/auth.repository';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { UserRepository } from 'src/app/repository/api_repository/user.repository';
import { SocketioService } from 'src/app/core/services/socket/socketio.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  refreshToken
  userInformation
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private auth: AuthRepository,
    private toast: ToastService,
    private user: UserRepository,
    private socket: SocketioService,
  ) { }

  ngOnInit(): void {
    this.refreshToken = {
      "refreshToken": localStorage.getItem('refreshToken')
    }
    this.getUserInfo();
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout() {
    this.socket.connect();
    localStorage.removeItem("accessToken");
  }

  getUserInfo() {
    let userId = localStorage.getItem('userId');
    this.user.getUser(userId).subscribe((user) => {
      this.userInformation = user;
    })
  }



}
